import React, { useEffect, useState } from "react";
import styles from "./OrderInfo.module.scss";
import ProductInfo from "../ProductInfo/ProductInfo";
import { IsFKTheme, IsGETheme, getBrandFriendlyName } from "../../../Utils/Themes";
import CheckBox from "../../CheckBox/CheckBox";
import smsAcceptActionDispatcher from "../../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import { Collapse, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { Bolt } from "@mui/icons-material";
import PriceSummarySmsAccept from "../PriceSummary/PriceSummarySmsAccept";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { IsAddonOrder, IsBedrift } from "../../../Utils/OrderHelper";

function OrderInfo({ orderInfo, salesTypeId, globalCheck, product, update, confirmed }) {
  const address = orderInfo.elhubData
    ? orderInfo.elhubData.address
    : orderInfo.powerSaleData.address;
  const zipcodeAndCity = orderInfo.elhubData
    ? `${orderInfo.elhubData.zipCode} ${orderInfo.elhubData.city}`
    : `${orderInfo.powerSaleData.zipCode} ${orderInfo.powerSaleData.city || ""}`;
  const meterNumber = orderInfo.elhubData
    ? orderInfo.elhubData.meterNumber
    : orderInfo.powerSaleData.meterNumber;
  const meterPointId = orderInfo.elhubData
    ? orderInfo.elhubData.meterPointId
    : orderInfo.powerSaleData.meterPointId;
  const [checked, setChecked] = useState(globalCheck);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!confirmed) {
      if (checked) {
        smsAcceptActionDispatcher.addOrderAccept(orderInfo.id);
      } else smsAcceptActionDispatcher.removeOrderAccept(orderInfo.id);
    }
  }, [checked]);

  useEffect(() => {
    if (!confirmed) {
      setChecked(globalCheck);
    }
  }, [globalCheck]);

  useEffect(() => {
    if (!confirmed && update) {
      setChecked(true);
    }
  }, [update]);

  const handleSetChecked = () => {
    if (!confirmed && timer === null) setChecked(!checked);
  };

  useEffect(() => {
    var extraInfoList = []; // Initialize an array to store the created objects
    if (orderInfo.campaign !== null) {
      const newInfo = {
        id: orderInfo?.productHubId,
        campaignId: orderInfo?.campaign.productHubId,
        brand: IsFKTheme ? "FKAS" : "TKAS"
      };
      extraInfoList.push(newInfo); // Add the created object to the array
    }

    if (extraInfoList.length > 0) {
      productActionDispatchers.getCampaignLength(extraInfoList);
    }
  }, [orderInfo]);

  const calculateRemainingTime = (orderDate) => {
    const currentTime = new Date().getTime();
    const orderTime = new Date(orderDate).getTime();
    const difference = orderTime + 24 * 60 * 60 * 1000 - currentTime - new Date().getTimezoneOffset() * 60 * 1000;

    if (difference > 0) {
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      return `${hours} timer ${minutes} minutter ${seconds} sekunder`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (
      !IsBedrift(salesTypeId) &&
      !IsAddonOrder(orderInfo.productTypeId) &&
      orderInfo?.overrideFreeze !== true
    ) {
      const interval = setInterval(() => {
        const remainingTime = calculateRemainingTime(orderInfo.created + "+02:00");
        if (remainingTime) {
          setTimer(remainingTime);
        } else {
          setTimer(null);
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTimer(null); // Ensure timer is null if overrideFreeze is true
    }
  }, [orderInfo, salesTypeId]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString + "+02:00");
    date.setDate(date.getDate() + 1); // Add one day
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    };
    return date.toLocaleDateString("nb-NO", options).replace(",", " klokken");
  };

  return (
    <div
      className={
        checked
          ? `whiteBox ${styles.container} ${IsFKTheme && styles.containerActiveFK} ${IsGETheme && styles.containerActiveGE}`
          : `whiteBox ${styles.container} ${IsFKTheme && styles.containerFK} ${IsGETheme && styles.containerGE}`
      }
    >
      {!checked ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <div className={styles.boxTitle}>
                {IsBedrift(salesTypeId)
                  ? "Anlegg"
                  : IsAddonOrder(orderInfo?.productTypeId)
                    ? ""
                    : "Strømavtale"}
              </div>
            </Grid>
            <br />
            <Grid item xs={12}>
              <div className={styles.grayText}>Adresse</div>
              <div>{address}</div>
              <div>{zipcodeAndCity}</div>
              <>
                {meterNumber && (
                  <div>
                    <div className={styles.grayText}>Målernummer:</div>
                    {meterNumber}
                  </div>
                )}
                {meterPointId && salesTypeId === 2 && (
                  <div>
                    <div className={styles.grayText}>MålepunktId:</div>
                    {meterPointId}
                  </div>
                )}
              </>
            </Grid>
          </Grid>
          <br />
        </>
      ) : (
        <Grid onClick={handleSetChecked} container>
          <Grid item xs={2}>
            <div className={styles.container}>
              <Bolt className={`${IsFKTheme && styles.icon}`} fontSize={"large"}></Bolt>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className={styles.infoSection}>
              <div className={styles.boxTitle}>{address}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={styles.floatRigth}>
              <CheckBox checked={checked} onClick={handleSetChecked}></CheckBox>
            </div>
          </Grid>
        </Grid>
      )}

      <Collapse in={!checked} timeout={"auto"}>
        {orderInfo.productHubId && <div className={styles.boxTitle}>Produkt</div>}
        <ProductInfo productId={orderInfo.productHubId} order={orderInfo} />
        <div onClick={handleSetChecked}>
          {!IsBedrift(salesTypeId) && (
            <>
              {orderInfo.rightOfWithdrawal === true && (
                <div>
                  <div className={styles.boxTitle}>Angrerett</div>
                  <div>{`Du har 14 dagers angrerett. For å benytte angreretten, må du kontakte ${getBrandFriendlyName()} eller bruke angreskjemaet.`}</div>
                </div>
              )}
              {orderInfo.rightOfWithdrawal === false && (
                <div>
                  <div className={styles.boxTitle}>Angrerett</div>
                  <div>{`Du har 14 dagers angrefrist. Fordi ønsket oppstartsdato er før angrefristen utløper, betaler du for leveransen, fram til du eventuelt benytter angreretten. For å benytte angreretten, må du kontakte ${getBrandFriendlyName()} eller bruke angreskjemaet.`}</div>
                </div>
              )}
            </>
          )}
          {salesTypeId !== 2 && salesTypeId !== 3 && (
            <PriceSummarySmsAccept
              order={orderInfo}
              power={true}
              addon={orderInfo.extraProducts !== null && orderInfo.extraProducts.length > 0}
              addonCampaign={
                orderInfo.extraProducts !== null &&
                orderInfo.extraProducts.some((x) => x.campaigns !== null && x.campaigns.length > 0)
              }
            />
          )}
        </div>

        <Grid container onClick={handleSetChecked} mt={2}>
          <Grid item xs={12}>
            {confirmed ? (
              <p className={styles.clickMe}>Akseptert</p>
            ) : (
              <FormGroup row className={styles.orderCheckbox}>
                <FormControlLabel
                  labelPlacement={"top"}
                  style={{ textAlign: "right" }}
                  control={
                    timer !== null ? (
                      <div>
                        <div className={styles.timer}>Tilbud kan aksepteres:</div>
                        <div>{formatDateTime(orderInfo.created)}</div>
                        <div>{timer}</div>
                      </div>
                    ) : (
                      <button
                        className={`btnPrimary ${styles.orderAcceptButton}`}
                        disabled={timer !== null}
                      >
                        Velg
                      </button>
                    )
                  }
                />
              </FormGroup>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}

export default OrderInfo;
